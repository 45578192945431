import React from 'react';
import {Modal, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

const UserOutlet = getOutlet('user');

getNewOutlet('admin-party-extra-user-add-modal', null, {autoDelete: false});

export function showAdminPartyExtraUserAddModal(data) {
  getOutlet('admin-party-extra-user-add-modal').update(data);
}

function AdminPartyExtraUserAddModal(props) {
  const [data, setData] = useOutlet('admin-party-extra-user-add-modal');

  return (
    <Modal
      title={null}
      footer={null}
      width={800}
      style={{padding: 0}}
      visible={!!data}
      onOk={() => setData(null)}
      onCancel={() => setData(null)}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {instance, users, extras, onRefresh, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalWrapper>
      <h2>新增額外名單</h2>
      <div className="container">
        <GenericForm
          instance={null}
          rjsfProps={rjsfProps}
          schema={{
            title: '',
            type: 'object',
            required: ['extras_addon'],
            properties: {
              extras_addon: {
                type: 'array',
                title: '',
                items: {
                  type: 'string',
                },
              },
            },
          }}
          uiSchema={{
            extras_addon: {
              items: {'ui:widget': 'admin-select-user-custom-widget'},
            },
          }}
          onSubmit={async (formData) => {
            let isExistsInExtras = false;
            let extraInExtra = [];

            message.info('正在檢核會員，請稍候');
            for (let i = 0; i < formData.extras_addon.length; i++) {
              const id = formData.extras_addon[i];
              const eId = instance.extras.findIndex((e) => e === id);

              if (eId > -1) {
                extraInExtra.push(eId);
              }
            }

            isExistsInExtras = extraInExtra.length > 0;

            if (isExistsInExtras) {
              message.warning('會員已在額外名單');
              AppActions.setLoading(false);
              return;
            }

            try {
              AppActions.setLoading(true);

              const nextExtras = [
                ...(instance.extras || []),
                ...formData.extras_addon,
              ];
              const nextUsers = [...instance.users];

              const targetUsers = await JStorage.fetchAllDocuments(
                'user_profile',
                {owner: {$in: [...formData.extras_addon]}},
                null,
                {
                  owner: 1,
                  name: 1,
                  LAWYER_NO: 1,
                  id_card_number: 1,
                  substate: 1,
                  phone: 1,
                },
              );

              for (let e of formData.extras_addon) {
                const eIdx = targetUsers.findIndex((user) => user.owner === e);
                const uIdx = nextUsers.findIndex((u) => u.user_id === e);

                if (uIdx === -1) {
                  nextUsers.push({
                    name: targetUsers[eIdx].name,
                    user_id: targetUsers[eIdx].owner,
                    LAWYER_NO: targetUsers[eIdx].LAWYER_NO,
                    state: targetUsers[eIdx].state,
                    substate: targetUsers[eIdx].substate,
                    phone: targetUsers.phone,
                    id_card_number: targetUsers[eIdx].id_card_number,
                    add_extra_staff: UserOutlet.getValue().sub,
                    add_extra_staff_name: UserOutlet.getValue().name,
                    add_extra_timestamp: new Date().getTime(),
                  });
                } else {
                  nextUsers[uIdx] = {
                    ...nextUsers[uIdx],
                    add_extra_staff: UserOutlet.getValue().sub,
                    add_extra_staff_name: UserOutlet.getValue().name,
                    add_extra_timestamp: new Date().getTime(),
                  };
                }
              }

              const nextInstance = await JStorage.updateDocument(
                'party',
                {id: instance.id},
                {
                  extras: nextExtras,
                  users: nextUsers,
                },
              );

              message.success('新增成功');

              onRefresh();
              setData(null);
            } catch (err) {
              console.warn(err);
              message.error('發生錯誤');
            } finally {
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  & > .container {
    margin: 10px auto;
    max-width: 1000px;
    width: 100%;

    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-table-cell {
      font-size: 16px;
    }
  }
`;

export default AdminPartyExtraUserAddModal;
