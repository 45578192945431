import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {
  Spin,
  Descriptions,
  Button,
  Input,
  Checkbox,
  Table,
  message,
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {getUserSubstateLabel} from '../../Utils/UserUtil';
import {
  generatePartyUserListToAoa,
  generatePartyMandatorListToAoa,
  generatePartyExtraListToAoa,
  generatePartyLessFreeGiftListToAoa,
  generatePartyWorkSheet,
  generatePartyLessFreeGiftListWorkSheet,
} from '../../Utils/PartyExportUtil';
import AdminDownloadExcelButton from '../../Components/AdminDownloadExcelButton';
import AdminPartyExtraUserListModal, {
  showAdminPartyExtraUserListModal,
} from '../../Components/AdminPartyExtraUserListModal';
import AdminPartyExtraUserAddModal, {
  showAdminPartyExtraUserAddModal,
} from '../../Components/AdminPartyExtraUserAddModal';
import AdminPartyMandatorUserCheckinModal, {
  showAdminPartyMandatorUserCheckinModal,
} from '../../Components/AdminPartyMandatorUserCheckinModal';

function mergeLocalStorageAndRemoteUsers(nextInstance) {
  const localAttendUsersJson = localStorage.getItem(
    `party_${nextInstance.id}_attend_users`,
  );
  const localAttendUsers = JSON.parse(localAttendUsersJson);

  if (Array.isArray(localAttendUsers) && localAttendUsers.length > 0) {
    const afterMergedLocalAttendUsers = [...nextInstance.users];

    for (let i = 0; i < localAttendUsers.length; i++) {
      const idx = afterMergedLocalAttendUsers.findIndex(
        (u) => u.user_id === localAttendUsers[i].user_id,
      );

      afterMergedLocalAttendUsers.splice(idx, 1);
      afterMergedLocalAttendUsers.unshift(localAttendUsers[i]);
    }

    return afterMergedLocalAttendUsers;
  }

  localStorage.setItem(
    `party_${nextInstance.id}_attend_users`,
    JSON.stringify([]),
  );

  return nextInstance.users;
}

function updateUserToLocalStorage(attendUser, instance) {
  const localAttendUsersJson = localStorage.getItem(
    `party_${instance.id}_attend_users`,
  );
  const localAttendUsers = JSON.parse(localAttendUsersJson);

  localAttendUsers.push(attendUser);
  localStorage.setItem(
    `party_${instance.id}_attend_users`,
    JSON.stringify(localAttendUsers),
  );
}

function deleteUserFromLocalStorage(user_id, instance) {
  const localAttendUsersJson = localStorage.getItem(
    `party_${instance.id}_attend_users`,
  );
  const localAttendUsers = JSON.parse(localAttendUsersJson);

  const targetUserIdx = localAttendUsers.findIndex(
    (u) => u.user_id === user_id,
  );

  if (targetUserIdx > -1) {
    localAttendUsers.splice(targetUserIdx, 1);
  }

  localStorage.setItem(
    `party_${instance.id}_attend_users`,
    JSON.stringify(localAttendUsers),
  );
}

function isSaveUsersAtLocalStorage(instance) {
  const localAttendUsersJson = localStorage.getItem(
    `party_${instance.id}_attend_users`,
  );
  const localAttendUsers = JSON.parse(localAttendUsersJson);

  if (Array.isArray(localAttendUsers) && localAttendUsers.length > 0) {
    return true;
  }

  return false;
}

function getLatestUserFromLocalStorage(instance) {
  const localAttendUsersJson = localStorage.getItem(
    `party_${instance.id}_attend_users`,
  );
  const localAttendUsers = JSON.parse(localAttendUsersJson);

  return localAttendUsers[localAttendUsers.length - 1] || {};
}

function getLatestUserFromRemote(instance) {
  return (
    instance.users
      .filter((u) => !!u.attend_time)
      .sort((a, b) => b.attend_time - a.attend_time)[0] || {}
  );
}

function getLessGiftsFromLocalStorage(instance) {
  const localLessGiftsJson = localStorage.getItem(
    `party_${instance.id}_less_gifts`,
  );

  if (localLessGiftsJson) {
    const localLessGifts = JSON.parse(localLessGiftsJson);
    return localLessGifts;
  }

  localStorage.setItem(`party_${instance.id}_less_gifts`, JSON.stringify([]));
  return [];
}

function updateLessGiftsToLocalStorage(less_free_gifts, instance) {
  localStorage.setItem(
    `party_${instance.id}_less_gifts`,
    JSON.stringify(less_free_gifts),
  );
}

function getUsersShouldAttendInfo(instance) {
  return (
    <>
      <div>{instance.users.length} 人</div>
      <div style={{background: 'yellow'}}>
        一般會員 (不含額外名單)：
        {
          instance.users
            .filter((user) => user.substate === '4')
            .filter((user) => !instance.extras.includes(user.user_id)).length
        }{' '}
        人
      </div>
      <div>
        一般會員 (含額外名單)：
        {
          instance.users
            .filter((user) => user.substate === '4')
            .filter((user) => instance.extras.includes(user.user_id)).length
        }{' '}
        人
      </div>
      <div>
        特別會員：
        {instance.users.filter((user) => user.substate === '5').length} 人
      </div>
      <div>
        外國法事務律師：
        {instance.users.filter((user) => user.substate === '6').length} 人
      </div>
    </>
  );
}

function getUsersActualAttendInfo(instance) {
  const users = mergeLocalStorageAndRemoteUsers(instance);

  return (
    <>
      {users.filter((user) => user.is_attend).length} 人
      <div style={{background: 'yellow'}}>
        一般會員 (不含額外名單)：
        {
          users
            .filter((user) => user.substate === '4')
            .filter((user) => !instance.extras.includes(user.user_id))
            .filter((user) => !user.mandator)
            .filter((user) => user.is_attend).length
        }{' '}
        人
      </div>
      <div>
        一般會員 (含額外名單)：
        {
          users
            .filter((user) => user.substate === '4')
            .filter((user) => instance.extras.includes(user.user_id))
            .filter((user) => !user.mandator)
            .filter((user) => user.is_attend).length
        }{' '}
        人
      </div>
      <div>
        特別會員：
        {
          users
            .filter((user) => user.substate === '5')
            .filter((user) => user.is_attend).length
        }{' '}
        人
      </div>
      <div>
        外國法事務律師：
        {
          users
            .filter((user) => user.substate === '6')
            .filter((user) => user.is_attend).length
        }{' '}
        人
      </div>
    </>
  );
}

function getUserMandatorAttendInfo(instance) {
  const users = mergeLocalStorageAndRemoteUsers(instance);
  return <>{users.filter((user) => !!user.mandator).length} 人</>;
}

function PartyCheckin(props) {
  const params = qs.parse(props.location.search);
  const inputRef = React.useRef(null);
  const {id} = params;

  const [showExportButtons, setShowExportButtons] = React.useState(false);
  const [instance, setInstance] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [extras, setExtras] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');
  const [lawyerNo, setLawyerNo] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [lessGifts, setLessGifts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchInstanceById = React.useCallback(async (id) => {
    try {
      AppActions.setLoading(true);
      const nextInstance = await JStorage.fetchOneDocument('party', {id});
      setInstance(nextInstance);

      //users
      if (Array.isArray(nextInstance.users) && nextInstance.users.length > 0) {
        const users = mergeLocalStorageAndRemoteUsers(nextInstance);
        setUsers(users);
      } else {
        setUsers([]);
      }

      //extras
      if (
        Array.isArray(nextInstance.extras) &&
        nextInstance.extras.length > 0
      ) {
        setExtras(nextInstance.extras);
      } else {
        setExtras([]);
      }

      //less gifts
      const localLessGifts = getLessGiftsFromLocalStorage(nextInstance);
      setLessGifts(localLessGifts);
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchInstanceById(id);
  }, [fetchInstanceById, id]);

  return (
    <Wrapper>
      <BackButton onClick={() => AppActions.navigate(`/admin/parties`)}>
        <ChevronLeft size={24} />
        <div className="back-btn-text">返回</div>
      </BackButton>

      <div className="container">
        {instance && (
          <div>
            <Descriptions
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <h2>{instance.title}</h2>
                  <Button
                    style={{marginLeft: 10}}
                    icon={<ReloadOutlined />}
                    onClick={async () => {
                      setLoading(true);
                      await fetchInstanceById(id);
                      setLoading(false);
                    }}>
                    重新整理
                  </Button>
                </div>
              }
              bordered={true}
              size="small">
              <Descriptions.Item label="應到人數">
                {getUsersShouldAttendInfo(instance)}
              </Descriptions.Item>
              <Descriptions.Item label="實到人數">
                {getUsersActualAttendInfo(instance)}
              </Descriptions.Item>
              <Descriptions.Item label="委託人數">
                {getUserMandatorAttendInfo(instance)}
              </Descriptions.Item>
              <Descriptions.Item label="額外名單">
                <Button
                  style={{margin: 5}}
                  onClick={() => {
                    showAdminPartyExtraUserListModal({
                      instance,
                      users,
                      extras,
                    });
                  }}>
                  清單
                </Button>
                <Button
                  style={{margin: 5}}
                  onClick={() => {
                    showAdminPartyExtraUserAddModal({
                      instance,
                      users,
                      extras,
                      onRefresh: () => fetchInstanceById(id),
                    });
                  }}>
                  新增
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="贈品缺少" span={3}>
                {instance.gifts?.map((gift) => {
                  const istLessGift =
                    lessGifts.findIndex((lg) => lg === gift) > -1;

                  return (
                    <Checkbox
                      value={gift}
                      checked={istLessGift}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const nextLessGifts = [...lessGifts];
                          const isNotExistIdx = nextLessGifts.findIndex(
                            (g) => g === e.target.value,
                          );

                          if (isNotExistIdx === -1) {
                            nextLessGifts.push(e.target.value);
                            setLessGifts(nextLessGifts);
                            updateLessGiftsToLocalStorage(
                              nextLessGifts,
                              instance,
                            );
                          }
                        }

                        if (!e.target.checked) {
                          const nextLessGifts = [...lessGifts];

                          const isExistIdx = nextLessGifts.findIndex(
                            (g) => g === e.target.value,
                          );

                          if (isExistIdx > -1) {
                            nextLessGifts.splice(isExistIdx, 1);
                            setLessGifts(nextLessGifts);
                            updateLessGiftsToLocalStorage(
                              nextLessGifts,
                              instance,
                            );
                          }
                        }
                      }}>
                      {gift}
                    </Checkbox>
                  );
                })}
              </Descriptions.Item>
              <Descriptions.Item label="會員搜尋" span={3}>
                <div className="row">
                  <Input
                    placeholder="請輸入會員序號、姓名、身分證字號、手機"
                    style={{maxWidth: 350, width: '100%', marginRight: 10}}
                    onChange={(e) => {
                      setSearchText(e.target.value);

                      if (!e.target.value) {
                        const targetUsers = mergeLocalStorageAndRemoteUsers(
                          instance,
                        );
                        setUsers(targetUsers);
                      }
                    }}
                    onPressEnter={(e) => {
                      let targetUsers;

                      if (searchText) {
                        targetUsers = mergeLocalStorageAndRemoteUsers(
                          instance,
                        ).filter(
                          (user) =>
                            user.name?.indexOf(searchText) > -1 ||
                            user.LAWYER_NO?.indexOf(searchText) > -1 ||
                            user.id_card_number?.indexOf(searchText) > -1 ||
                            user.phone?.indexOf(searchText) > -1,
                        );
                      } else {
                        targetUsers = mergeLocalStorageAndRemoteUsers(instance);
                      }

                      setUsers(targetUsers);
                    }}
                  />
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="會員報到" span={3}>
                <div className="row">
                  <Input
                    autoFocus
                    placeholder="請輸入會員序號"
                    ref={inputRef}
                    value={lawyerNo}
                    style={{maxWidth: 200, width: '100%', marginRight: 10}}
                    onChange={async (e) => {
                      setLawyerNo(e.target.value);

                      if (!e.target.value) {
                        message.info('請輸入會員序號');
                        return;
                      }

                      const targerUserIdx = instance.users.findIndex(
                        (user) => user.LAWYER_NO === e.target.value,
                      );

                      if (targerUserIdx === -1) {
                        // message.info('找不到會員');
                        return;
                      }

                      try {
                        AppActions.setLoading(true);
                        message.info('正在報到');

                        const attendUser = await AppActionsEx.adminPartyAttendByUser(
                          {
                            user_id: instance.users[targerUserIdx].user_id,
                            party_id: instance.id,
                            less_free_gifts: instance.users[targerUserIdx]
                              .add_extra_timestamp
                              ? []
                              : lessGifts,
                          },
                        );

                        updateUserToLocalStorage(attendUser, instance);
                        const users = mergeLocalStorageAndRemoteUsers(instance);
                        setUsers(users);

                        message.success('報到成功');
                        setPage(1);
                        setLawyerNo('');

                        inputRef.current?.focus({
                          cursor: 'start',
                        });
                      } catch (err) {
                        console.warn(err);

                        if (err.status === 433) {
                          window.confirm(`${err.response.error}`);
                          setLawyerNo('');
                        } else {
                          message.error(`發生錯誤: ${err}`);
                        }
                      } finally {
                        AppActions.setLoading(false);
                      }
                    }}
                  />
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </div>

      <div className="container">
        {loading && (
          <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  spin
                />
              }
            />
            <span style={{fontSize: 14}}>請稍候</span>
          </div>
        )}

        {!loading && instance && (
          <Table
            columns={[
              {
                key: 'name',
                title: '姓名',
                dataIndex: 'name',
                render: (_, r) => {
                  const is_attend = r.is_attend;

                  const is_attend_latest = isSaveUsersAtLocalStorage(instance)
                    ? getLatestUserFromLocalStorage(instance).user_id ===
                      r.user_id
                    : getLatestUserFromRemote(instance).attend_time ===
                      r.attend_time;

                  return (
                    <div
                      style={{
                        padding: 5,
                        backgroundColor: is_attend
                          ? is_attend_latest
                            ? 'var(--primaryColor)'
                            : 'var(--secondColor)'
                          : undefined,
                        textAlign: 'center',
                        color: is_attend ? '#ffffff' : undefined,
                        borderRadius: 10,
                      }}>
                      {r.name}
                    </div>
                  );
                },
              },
              {key: 'LAWYER_NO', title: '會員序號', dataIndex: 'LAWYER_NO'},
              {
                key: 'substate',
                title: '次狀態',
                dataIndex: 'substate',
                render: (_, r) => getUserSubstateLabel(r.substate),
              },
              {
                key: 'id_card_number',
                title: '身分證字號',
                dataIndex: 'id_card_number',
              },
              {
                key: 'phone',
                title: '手機',
                dataIndex: 'phone',
              },
              {
                key: 'mandator',
                title: '委託人姓名',
                dataIndex: 'mandator',
                render: (_, r) => {
                  const mandatorUser = instance.users.find(
                    (u) => u.user_id === r.mandator,
                  );

                  return <div>{r.mandator ? mandatorUser?.name : ''}</div>;
                },
              },
              {
                key: 'note',
                title: '備註',
                dataIndex: 'title',
                width: 150,
                render: (_, r) => {
                  return (
                    <div>
                      {r.add_extra_timestamp && <div>額外名單</div>}
                      {r.note && <div>{r.note}</div>}
                      {Array.isArray(r.less_free_gift) &&
                        r.less_free_gift.length > 0 && (
                          <div>
                            缺：
                            {r.less_free_gift.map(
                              (gift, idx) =>
                                `${gift}${
                                  idx + 1 !== r.less_free_gift.length
                                    ? '、'
                                    : ''
                                }`,
                            )}
                          </div>
                        )}
                    </div>
                  );
                },
              },
              {
                key: 'action',
                title: '功能列',
                dataIndex: 'action',
                width: 180,
                render: (_, r) => {
                  return r.is_attend ? (
                    <div>
                      <Button
                        style={{margin: 3}}
                        onClick={async () => {
                          try {
                            AppActions.setLoading(true);
                            message.info('取消報到中');
                            const nextInstance = await AppActionsEx.adminPartyAttendAbortByUser(
                              {user_id: r.user_id, party_id: instance.id},
                            );

                            nextInstance.id = nextInstance._id.$oid;
                            delete nextInstance._id;

                            setInstance(nextInstance);

                            deleteUserFromLocalStorage(r.user_id, nextInstance);
                            const users = mergeLocalStorageAndRemoteUsers(
                              instance,
                            );
                            setUsers(users);

                            message.success('取消報到成功');

                            inputRef.current?.focus({
                              cursor: 'start',
                            });
                          } catch (err) {
                            console.warn(err);
                            message.error('發生錯誤');
                          } finally {
                            AppActions.setLoading(false);
                          }
                        }}>
                        取消報到
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        style={{margin: 3}}
                        onClick={async () => {
                          try {
                            AppActions.setLoading(true);
                            message.info('正在報到');

                            const attendUser = await AppActionsEx.adminPartyAttendByUser(
                              {
                                user_id: r.user_id,
                                party_id: instance.id,
                                less_free_gifts: r.add_extra_timestamp
                                  ? []
                                  : lessGifts,
                              },
                            );

                            updateUserToLocalStorage(attendUser, instance);
                            const users = mergeLocalStorageAndRemoteUsers(
                              instance,
                            );
                            setUsers(users);

                            message.success('報到成功');
                            setPage(1);
                          } catch (err) {
                            console.warn(err);

                            if (err.status === 433) {
                              window.confirm(`${err.response.error}`);
                              setLawyerNo('');
                            } else {
                              message.error(`發生錯誤: ${err}`);
                            }
                          } finally {
                            AppActions.setLoading(false);
                          }
                        }}>
                        報到
                      </Button>
                      <Button
                        style={{margin: 3}}
                        onClick={async () => {
                          showAdminPartyMandatorUserCheckinModal({
                            user: r,
                            instance,
                            users,
                            extras,
                            less_free_gifts: r.add_extra_timestamp
                              ? []
                              : lessGifts,
                            setInstance,
                            setUsers,
                            setPage,
                            setLawyerNo,
                            mergeLocalStorageAndRemoteUsers,
                            updateUserToLocalStorage,
                          });
                        }}>
                        委託
                      </Button>
                    </div>
                  );
                },
              },
            ]}
            dataSource={users}
            pagination={{
              position: ['topRight'],
              size: 'small',
              showSizeChanger: false,
              pageSize: pageSize,
              current: page,
              showTotal: (total) =>
                `${searchText && '搜尋結果'} 共 ${users.length} 筆`,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        )}
        <div style={{display: 'flex', marginTop: 20}}>
          <Button
            type="text"
            style={{margin: 2}}
            icon={showExportButtons ? <LeftOutlined /> : <RightOutlined />}
            onClick={() => setShowExportButtons(!showExportButtons)}>
            {showExportButtons ? '隱藏' : '顯示'}下載按鈕列
          </Button>
          {showExportButtons && (
            <div style={{marginRight: 2}}>
              <AdminDownloadExcelButton
                buttonStyle={{margin: 2}}
                title="出席紀錄"
                collection="party"
                query={{id: instance.id}}
                sort={null}
                filename={`${instance.title}_出席紀錄.xlsx`}
                updateWorkSheet={(ws) => generatePartyWorkSheet(ws)}
                recordsToAoa={async (records) =>
                  await generatePartyUserListToAoa({records, instance})
                }
              />
              <AdminDownloadExcelButton
                buttonStyle={{margin: 2}}
                title="委託出席紀錄"
                collection="party"
                query={{id: instance.id}}
                sort={null}
                filename={`${instance.title}_委託出席紀錄.xlsx`}
                updateWorkSheet={(ws) => generatePartyWorkSheet(ws)}
                recordsToAoa={async (records) =>
                  await generatePartyMandatorListToAoa({records, instance})
                }
              />
              <AdminDownloadExcelButton
                buttonStyle={{margin: 2}}
                title="額外名單出席紀錄"
                collection="party"
                query={{id: instance.id}}
                sort={null}
                filename={`${instance.title}_額外名單出席紀錄.xlsx`}
                updateWorkSheet={(ws) => generatePartyWorkSheet(ws)}
                recordsToAoa={async (records) =>
                  await generatePartyExtraListToAoa({records, instance})
                }
              />
              <AdminDownloadExcelButton
                buttonStyle={{margin: 2}}
                title="贈品缺少紀錄"
                collection="party"
                query={{id: instance.id}}
                sort={null}
                filename={`${instance.title}_贈品缺少紀錄.xlsx`}
                updateWorkSheet={(ws) =>
                  generatePartyLessFreeGiftListWorkSheet(ws)
                }
                recordsToAoa={async (records) =>
                  await generatePartyLessFreeGiftListToAoa({records, instance})
                }
              />
            </div>
          )}
        </div>
      </div>

      <AdminPartyExtraUserListModal />
      <AdminPartyExtraUserAddModal />
      <AdminPartyMandatorUserCheckinModal />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  min-height: 100vh;

  & > .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto 5px;

    .ant-checkbox-wrapper,
    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-table-cell {
      font-size: 16px;
    }

    .ant-table-pagination.ant-pagination {
      margin: 10px 0px;
    }
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;

export default withPageEntry(PartyCheckin);
