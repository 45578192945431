import React from 'react';
import {Modal, Descriptions, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

const UsersOutlet = getOutlet('users');

getNewOutlet('admin-party-mandator-user-checkin-modal', null, {
  autoDelete: false,
});

export function showAdminPartyMandatorUserCheckinModal(data) {
  getOutlet('admin-party-mandator-user-checkin-modal').update(data);
}

function AdminPartyMandatorUserCheckinModal(props) {
  const [data, setData] = useOutlet('admin-party-mandator-user-checkin-modal');

  return (
    <Modal
      title={null}
      footer={null}
      width={550}
      style={{padding: 0}}
      visible={!!data}
      onOk={() => setData(null)}
      onCancel={() => setData(null)}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {
    user,
    instance,
    users,
    extras,
    less_free_gifts,
    setInstance,
    setUsers,
    setPage,
    setLawyerNo,
    mergeLocalStorageAndRemoteUsers,
    updateUserToLocalStorage,
    setData,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalWrapper>
      <Descriptions title={<h2>委託報到</h2>} bordered={true}>
        <Descriptions.Item label="會員序號">{user.LAWYER_NO}</Descriptions.Item>
        <Descriptions.Item label="會員姓名">{user.name}</Descriptions.Item>
      </Descriptions>

      <div className="container">
        <GenericForm
          rjsfProps={rjsfProps}
          instance={null}
          schema={{
            title: '',
            type: 'object',
            required: [],
            properties: {
              mandator_lawyer_no: {
                type: 'string',
                title: '被委託會員序號 (擇一輸入)',
              },
              mandator_id_card_number: {
                type: 'string',
                title: '被委託會員身分證字號 (擇一輸入)',
              },
              note: {
                type: 'string',
                title: '備註',
              },
            },
          }}
          onSubmit={async (formData) => {
            if (
              !formData.mandator_lawyer_no &&
              !formData.mandator_id_card_number
            ) {
              AppActions.setLoading(false);
              message.warning('被委託會員序號或身分證字號，須擇一輸入');
              return;
            }

            if (
              !!formData.mandator_lawyer_no &&
              formData.mandator_lawyer_no === user.LAWYER_NO
            ) {
              AppActions.setLoading(false);
              message.warning('會員序號與被委託會員序號相同');
              return;
            }

            if (
              !!formData.mandator_id_card_number &&
              formData.mandator_id_card_number === user.id_card_number
            ) {
              AppActions.setLoading(false);
              message.warning('會員身分證字號與被委託會員身分證字號相同');
              return;
            }

            if (
              formData.mandator_lawyer_no &&
              formData.mandator_id_card_number
            ) {
              AppActions.setLoading(false);
              message.warning('被委託會員序號或身分證字號，擇一輸入即可');
              return;
            }

            if (formData.mandator_lawyer_no) {
              const isVerified = UsersOutlet.getValue().some(
                (user) => user.LAWYER_NO === formData.mandator_lawyer_no,
              );

              if (!isVerified) {
                AppActions.setLoading(false);
                message.warning('被委託會員序號不合法');
                return;
              }
            }

            if (formData.mandator_id_card_number) {
              const isVerified = UsersOutlet.getValue().some(
                (user) =>
                  user.id_card_number === formData.mandator_id_card_number,
              );

              if (!isVerified) {
                AppActions.setLoading(false);
                message.warn('被委託身分證字號不合法');
                return;
              }
            }

            try {
              AppActions.setLoading(true);

              const mandatorUser = UsersOutlet.getValue().find(
                (user) =>
                  user.LAWYER_NO === formData.mandator_lawyer_no ||
                  user.id_card_number === formData.mandator_id_card_number,
              );

              if (mandatorUser) {
                const attendUser = await AppActionsEx.adminPartyAttendByMandatorUser(
                  {
                    party_id: instance.id,
                    user_id: user.user_id,
                    mandator: mandatorUser.owner,
                    less_free_gifts,
                    note: formData.note,
                  },
                );

                updateUserToLocalStorage(attendUser, instance);
                const users = mergeLocalStorageAndRemoteUsers(instance);

                setUsers(users);
                setPage(1);
                message.success('委託報到成功');
                setData(null);
              } else {
                message.warning('找不到被委託會員');
              }
            } catch (err) {
              console.warn(err);

              if (err.status === 433) {
                window.confirm(`${err.response.error}`);
                setLawyerNo('');
              } else {
                message.error(`發生錯誤: ${err}`);
              }
            } finally {
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  & > .container {
    margin: 10px auto;
    max-width: 1000px;
    width: 100%;

    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-table-cell {
      font-size: 16px;
    }
  }
`;

export default AdminPartyMandatorUserCheckinModal;
