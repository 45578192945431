import React from 'react';
import {Modal, Descriptions, Table} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('admin-party-extra-user-list-modal', null, {autoDelete: false});

export function showAdminPartyExtraUserListModal(data) {
  getOutlet('admin-party-extra-user-list-modal').update(data);
}

function AdminPartyExtraUserListModal(props) {
  const [data, setData] = useOutlet('admin-party-extra-user-list-modal');

  return (
    <Modal
      title={null}
      footer={null}
      width={1000}
      style={{padding: 0}}
      visible={!!data}
      onOk={() => setData(null)}
      onCancel={() => setData(null)}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {instance, extras, setData} = props;

  return (
    <ModalWrapper>
      <div className="container">
        <Descriptions title={<h2>額外名單</h2>} bordered={true} size="small">
          <Descriptions.Item label="未報到人數">
            {
              instance.users
                .filter((user) => extras.includes(user.user_id))
                .filter((user) => !user.is_attend).length
            }
            人
          </Descriptions.Item>
          <Descriptions.Item label="已報到人數">
            {
              instance.users
                .filter((user) => extras.includes(user.user_id))
                .filter((user) => user.is_attend).length
            }
            人
          </Descriptions.Item>
          <Descriptions.Item label="額外名單總人數">
            {extras.length} 人
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className="container">
        <Table
          columns={[
            {
              key: 'name',
              title: '姓名',
              dataIndex: 'name',
              render: (_, r) => {
                const is_attend = r.is_attend;
                const is_attend_lastet =
                  instance.users
                    .filter((u) => !!u.attend_time)
                    .sort((a, b) => b.attend_time - a.attend_time)[0]
                    ?.attend_time === r.attend_time;

                return (
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: is_attend
                        ? is_attend_lastet
                          ? '#B49664'
                          : '#6E2D8C'
                        : undefined,
                      textAlign: 'center',
                      color: is_attend ? '#ffffff' : undefined,
                      borderRadius: 10,
                      maxWidth: 95,
                      width: '100%',
                    }}>
                    {r.name}
                  </div>
                );
              },
            },
            {key: 'LAWYER_NO', title: '會員序號', dataIndex: 'LAWYER_NO'},
            {
              key: 'is_attend',
              title: '報到',
              dataIndex: 'is_attend',
              render: (_, r) => <div>{r.is_attend ? '是' : '否'}</div>,
            },
            {key: 'is_trust', title: '委託'},
          ]}
          dataSource={instance.users.filter((user) =>
            extras.includes(user.user_id),
          )}
        />
      </div>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  & > .container {
    margin: 0 auto 10px;

    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-table-cell {
      font-size: 16px;
    }
  }
`;

export default AdminPartyExtraUserListModal;
